import React from 'react'

import { BodyRegular, BodySemibold } from '../typography'

import { DownloadLink, AppTitle, AppText, App } from './styled'

interface Props {
  imgSrc: string
  title: string
  subtitle: string
  ctaText: string
  ctaLink: string
}

export const InstallApp = ({
  imgSrc,
  title,
  subtitle,
  ctaText,
  ctaLink,
}: Props): JSX.Element => (
  <App>
    <img src={imgSrc} alt={title} />
    <AppText>
      <AppTitle>{title}</AppTitle>
      <BodyRegular>{subtitle}</BodyRegular>
      <DownloadLink
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={ctaLink}
      >
        <BodySemibold>{ctaText}</BodySemibold>
      </DownloadLink>
    </AppText>
  </App>
)
