import React, { useState } from 'react'

import { AppLinksSwitcher } from '../../components/install-apps/AppLinksSwitcher'
import { InstallApp } from '../../components/install-apps/InstallApp'
import {
  PRIMARY_DESCRIPTION,
  SECONDARY_DESCRIPTION,
  IOS_TITLE,
  CHROME_TITLE,
  SLACK_TITLE,
  IOS_EXEC_SUBTITLE,
  IOS_ASSISTANT_SUBTITLE,
  CHROME_EXEC_SUBTITLE,
  CHROME_ASSISTANT_SUBTITLE,
  SLACK_SUBTITLE,
  IOS_CTA_TEXT,
  CHROME_CTA_TEXT,
  SLACK_CTA_TEXT,
  EXEC_IOS_APPSTORE_URL,
  ASSISTANT_IOS_APPSTORE_URL,
  EXEC_CHROME_STORE_URL,
  ASSISTANT_CHROME_STORE_URL,
  SLACK_URL,
} from '../../components/install-apps/constants'
import chrome from '../../components/install-apps/illustrations/chrome.png'
import doubleIOS from '../../components/install-apps/illustrations/doubleiOS.png'
import execIOS from '../../components/install-apps/illustrations/execiOS.png'
import slack from '../../components/install-apps/illustrations/slack.png'
import {
  Outer,
  Main,
  Heading,
  AppsOuter,
  PrimaryDescription,
} from '../../components/install-apps/styled'
import Page from '../../components/layout/Page'
import { SubheadRegular } from '../../components/typography'

const InstallApps = (): JSX.Element => {
  const [isExec, setAsExec] = useState(true)
  return (
    <Page
      head={{
        title: 'Get our apps',
        description: PRIMARY_DESCRIPTION,
      }}
      nav={{
        withNavLinks: false,
      }}
    >
      <Outer>
        <header>
          <Heading>{'Get our apps'}</Heading>
          <PrimaryDescription>{PRIMARY_DESCRIPTION}</PrimaryDescription>
          <SubheadRegular style={{ textAlign: 'center' }}>
            {SECONDARY_DESCRIPTION}
          </SubheadRegular>
        </header>
        <Main>
          <AppLinksSwitcher isExec={isExec} setAsExec={setAsExec} />
          <AppsOuter>
            <InstallApp
              imgSrc={isExec ? execIOS : doubleIOS}
              title={IOS_TITLE}
              subtitle={isExec ? IOS_EXEC_SUBTITLE : IOS_ASSISTANT_SUBTITLE}
              ctaText={IOS_CTA_TEXT}
              ctaLink={
                isExec ? EXEC_IOS_APPSTORE_URL : ASSISTANT_IOS_APPSTORE_URL
              }
            />
            <InstallApp
              imgSrc={chrome}
              title={CHROME_TITLE}
              subtitle={
                isExec ? CHROME_EXEC_SUBTITLE : CHROME_ASSISTANT_SUBTITLE
              }
              ctaText={CHROME_CTA_TEXT}
              ctaLink={
                isExec ? EXEC_CHROME_STORE_URL : ASSISTANT_CHROME_STORE_URL
              }
            />
            {isExec && (
              <InstallApp
                imgSrc={slack}
                title={SLACK_TITLE}
                subtitle={SLACK_SUBTITLE}
                ctaText={SLACK_CTA_TEXT}
                ctaLink={SLACK_URL}
              />
            )}
          </AppsOuter>
        </Main>
      </Outer>
    </Page>
  )
}

export default InstallApps
