import type { ComponentProps } from 'react'
import React from 'react'
import styled, { css } from 'styled-components'

import { Color } from '../theme'
import { bodyMediumStyle } from '../typography'

const Slider = styled.span`
  border-radius: 16px;
  display: block;
  height: 56px;
  width: 100%;
  background-color: ${Color.GRAY_2};
  transition: 0.4s;
  margin: 0 auto;
  position: absolute;

  &::before {
    border-radius: 12px;
    position: absolute;
    content: '';
    height: 48px;
    width: calc(100% / 2 - 5px);
    right: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }
`

const LabelWrapper = styled.div`
  display: block;
  z-index: 10;
  margin: 0 auto;
  height: 56px;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
`

const SliderLabel = styled.span<{ isActive?: boolean }>`
  ${bodyMediumStyle({})};
  color: ${Color.GRAY_6};
  display: inline-block;
  z-index: 10;
  flex: 1 1 100%;
  text-align: center;

  ${({ isActive }) =>
    !!isActive &&
    css`
      color: black;
    `}
`

const SliderWrapper = styled.label<{ $width: number }>`
  cursor: pointer;
  display: block;
  height: 56px;
  width: ${({ $width }) => $width}px;
  max-width: 100%;
  color: black;
  margin: 0 auto;

  input {
    display: none;

    &:focus + ${Slider} {
      box-shadow: 0 0 0.0625rem white;
    }

    &:checked + ${Slider}:before {
      transform: translateX(calc(-100% - 2px));
    }
  }
`

type Props = ComponentProps<typeof SliderWrapper> & {
  leftLabel: string
  rightLabel: string
  isLeft: boolean
  onSwitch: (isLeft: boolean) => void
  inputName?: string
  $width: number
}

const Switcher = ({
  leftLabel,
  rightLabel,
  isLeft,
  onSwitch,
  inputName,
  $width,
  ...wrapperProps
}: Props): JSX.Element => (
  <SliderWrapper $width={$width} {...wrapperProps}>
    <LabelWrapper>
      <SliderLabel isActive={isLeft}>{leftLabel}</SliderLabel>
      <SliderLabel isActive={!isLeft}>{rightLabel}</SliderLabel>
    </LabelWrapper>
    <input
      name={inputName}
      type={'checkbox'}
      checked={isLeft}
      onChange={() => onSwitch(!isLeft)}
    />
    <Slider />
  </SliderWrapper>
)

export default Switcher
