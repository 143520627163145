import React from 'react'

import Switcher from '../forms/Switcher'

interface Props {
  isExec: boolean
  setAsExec: (isExec: boolean) => void
}

export const AppLinksSwitcher = ({ isExec, setAsExec }: Props): JSX.Element => (
  <section>
    <Switcher
      leftLabel={'Executive'}
      rightLabel={'Assistant'}
      inputName={'userType'}
      isLeft={isExec}
      onSwitch={setAsExec}
      $width={290}
    />
  </section>
)
