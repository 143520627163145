export const PRIMARY_DESCRIPTION =
  'Your safe space to interact with your assistant away from distraction.'
export const SECONDARY_DESCRIPTION = 'Simple, efficient, integrated.'

export const IOS_TITLE = 'Double on iOS'
export const CHROME_TITLE = 'Double on Chrome'
export const SLACK_TITLE = 'Double on Slack'

export const IOS_EXEC_SUBTITLE = 'Send new tasks on-the-go using voice or text.'
export const IOS_ASSISTANT_SUBTITLE =
  "Stay in sync with your executive when you're on-the-go."

export const CHROME_EXEC_SUBTITLE =
  'Create tasks for your assistant directly from Gmail.'
export const CHROME_ASSISTANT_SUBTITLE =
  'Transform emails into tasks directly from Gmail.'

export const SLACK_SUBTITLE = 'Create and follow up on tasks right from Slack.'

export const IOS_CTA_TEXT = 'Download on the App Store →'
export const CHROME_CTA_TEXT = 'Add to Chrome →'
export const SLACK_CTA_TEXT = 'Add to Slack →'

export const EXEC_IOS_APPSTORE_URL =
  'https://apps.apple.com/us/app/double-work-better-together/id1437198408'
export const ASSISTANT_IOS_APPSTORE_URL =
  'https://apps.apple.com/us/app/double-for-assistants/id1437668254'

export const EXEC_CHROME_STORE_URL =
  'https://chrome.google.com/webstore/detail/double/bhnjggkfcbjaiaobogobmfbppfakibho'
export const ASSISTANT_CHROME_STORE_URL =
  'https://chrome.google.com/webstore/detail/double-%E2%80%93-extension-for-as/bcmkfgihonfamfjlopoonciaonkpkfna'

export const SLACK_URL =
  'https://app.withdouble.com/settings/integrations/slack'
