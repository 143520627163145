import styled, { css } from 'styled-components'

import { screenSize3 } from '../../components/mixins'
import { Color } from '../../components/theme'
import { Heading1, Heading2, SubheadRegular } from '../../components/typography'

export const Outer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-bottom: 128px;
`

export const Main = styled.main`
  margin-top: 72px;
`

export const Heading = styled(Heading1)`
  text-align: center;
`

export const DownloadLink = styled.a`
  cursor: pointer;
  display: block;
  margin-top: 24px;

  p {
    color: ${Color.PURPLE_5} !important;

    &:hover {
      color: ${Color.PURPLE_6} !important;
    }
  }
`

export const AppTitle = styled(Heading2)`
  margin: unset;
  margin-top: 21px;
  margin-bottom: 16px;

  ${screenSize3(css`
    margin-top: 40px;
  `)}
`

export const AppText = styled.div`
  margin-left: 48px;

  ${screenSize3(css`
    margin-left: 0;
    text-align: center;
  `)}
`

export const App = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 64px;

  &:last-of-type {
    margin-bottom: 64px;
  }

  img {
    height: 220px;
    width: 220px;
    border-radius: 20px;

    ${screenSize3(css`
      margin: 0 auto;
    `)}
  }

  ${screenSize3(css`
    flex-direction: column;
  `)}
`

export const AppsOuter = styled.section`
  margin: 72px auto 52px auto;
  width: 680px;

  ${screenSize3(css`
    width: 280px;
  `)}
`

export const PrimaryDescription = styled(SubheadRegular)`
  margin-top: 24px !important;
  text-align: center;
`
